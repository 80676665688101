import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { useTranslation } from 'react-i18next'
import { AnnouncementBanner } from '../components/announcement-banner'
import { Hero } from '../components/hero'
import { LogoClouds } from '../components/logo-clouds'
import { CTA } from '../components/cta'
import { FAQ } from '../components/faq'
import { HowItWorks } from '../components/how-it-works'
import { PopularTests } from '../components/popular-tests'
import { ProductBanner } from '../components/product-banner'
import { ProductItem } from '../components/product-card'
import { BlogRecent } from '../components/blog/blog-recent'

const hrefLangLinks = {
  en: '',
  fr: '',
  nl: '',
  de: '',
}

const IndexPage = ({ data: { shopifyProducts, homePage } }) => {
  const { t } = useTranslation()
  const mergedProducts = homePage.popularTests.map(t1 => ({
    ...t1,
    ...shopifyProducts.nodes.find(t2 => t2.storefrontId === `gid://shopify/Product/${t1.shopifyId}`),
  }))

  const parsedProducts = mergedProducts.map(
    product =>
      ({
        title: product.title,
        description: product.extra,
        price: product.priceRangeV2.minVariantPrice.amount,
        image: product.featuredImage.localFile.childImageSharp.gatsbyImageData,
        observations: product.observation,
        slug: `${t('link-base') + t('our-tests-url')}/${
          product.category.slug
        }/${product.slug}`,
      } as ProductItem)
  )

  const heroInfo = {
    heroTitle: homePage.heroTitle,
    heroDescription: homePage.heroDescription,
    heroImage: homePage.heroImage,
    heroSecondTitle: homePage.heroSecondTitle,
    heroSecondDescription: homePage.heroSecondDescription,
    heroAction: homePage.heroAction,
    heroActionUrl: homePage.heroActionUrl,
  }

  const popularTestsInfo = {
    title: homePage.popularTestsTitle,
    subtitle: homePage.popularTestsSubtitle,
  }
  
  return (
    <Layout hrefLangLinks={hrefLangLinks} seoMetaTags={homePage.seoMetaTags}>
      <ProductBanner />
      {/* This is the grey announcement banner */}
      {homePage.announcementBannerText && <AnnouncementBanner text={homePage.announcementBannerText} />}
      {heroInfo.heroTitle && <Hero data={heroInfo} />}
      <PopularTests text={popularTestsInfo} products={parsedProducts} />
      <HowItWorks />
      <FAQ faqItems={homePage.faq.faqBlock} />
      <BlogRecent />
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query IndexQuery($locale: String!) {
    homePage: datoCmsHomePageDepypere(locale: { eq: $locale }) {
      heroTitle
      heroDescription
      heroImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      heroSecondTitle
      heroSecondDescription
      heroAction
      heroActionUrl
      announcementBannerText
      faq {
        faqBlock {
          title: question
          description: answerNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      popularTestsTitle
      popularTestsSubtitle
      popularTests {
        title
        description
        typeDescription
        shopifyId
        extra
        observation {
          title: name
        }
        category {
          originalId
          name
          description
          slug
          image {
            fixed(width: 48) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
        slug
      }
    }
    shopifyProducts: allShopifyProduct {
      nodes {
        id
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
        storefrontId
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
